<template>
  <div id="commercant-content" class="container-fluid">
    <div class="row">
      <div class="commercant-left d-none d-lg-flex col-md-3">
        <FavouriteFiltres :shops="shops" />
      </div>

      <div class="commercant-right col-12 col-lg-9">
        <Breadcrumb favourite class="pb-lg-5" />
        <div class="favoris-search">
          <div>
            <router-link :to="{ name: 'shops' }" class="d-flex align-items-center">
              <img src="@/assets/continue-buying-icon.png">
              <span>{{ $t('favourite.back') }}</span>
            </router-link>
          </div>
        </div>
        <a id="products"></a>
        <Produits
          v-if="currentFilteredShop"
          :filter-shops="shops"
          :filter-id="filterId"
          :shop-id="$route.query.shop || currentFilteredShop.id"
          :shop-slug="currentFilteredShop.slug"
          :search="search"
          :title="$t('favourite.products')"
          :empty-message="$t('favourite.noProduct')"
        />
        <div v-if="shops.length" class="favoris-search">
          <div>
            <router-link :to="{ name: 'shop', params: { shopSlug: $route.query.shop || shops[0].id } }" class="d-flex align-items-center">
              <img src="@/assets/continue-buying-icon.png">
              <span>{{ $t('favourite.back') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FavouriteFiltres from '@/components/Favourite/FavouriteFiltres.vue'
import Produits from '@/components/Produits/Produits.vue'
import Breadcrumb from '@/components/UI/Breadcrumb.vue'

export default {
  name: 'FavouriteContent',
  components: {
    FavouriteFiltres,
    Produits,
    Breadcrumb
  },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    loading: false,
    filterId: null,
    shops: [],
    search: null
  }),
  computed: {
    partner() {
      return this.$store.getters['partner/current']
    },
    currentFilteredShop() {
      return this.$store.getters['shop/current']
    }
  },
  watch: {
    partnerId: 'load',
    $route: 'switchShop'
  },
  mounted() {
    return this.load()
      .then(() => {
        if (this.$route.query.shop) {
          return this.switchShop()
        }
      })
  },
  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('partner/filterFavourite', { id: this.partnerId })
        .then(filter => {
          this.filterId = filter.id
          return this.$store.dispatch('filter/listShops', { id: filter.id, partnerId: filter.partnerId })
        })
        .then(shops => {
          this.shops = shops
          this.$emit('loaded', true)
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    switchShop() {
      this.loading = true
      return this.$store.dispatch('shop/get', { id: this.$route.query.shop })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#commercant-content {
  padding-bottom: 150px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 180px;
  }
}
.commercant-left {
  padding: 60px 30px;

  @include media-breakpoint-up(md) {
    padding: 95px 30px;
  }

  @include media-breakpoint-up(lg) {
    padding: 145px 40px;
  }
}
.commercant-right {
  padding: 15px;

  @include media-breakpoint-up(lg) {
    padding: 40px 90px 0 0;
  }
}
.favoris-search {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }
  span {
    color: var(--purple-color);
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-decoration: none;
    text-align: left;
  }
  img {
    margin-right: 10px;
    width: 45px;
    height: 100%;
  }
  a, a:hover {
    text-decoration: none;
  }
}
.favoris-search > div {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.commercant-favoris button {
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.commercant-favoris:not(.favoris) {
  button.favoris-full {
    display: none;
  }
  button.favoris-empty {
    display: flex;
    align-items: center;
  }
}
.commercant-favoris.favoris {
  button.favoris-full {
    display: flex;
    align-items: center;
  }
  button.favoris-empty {
    display: none;
  }
}
</style>
