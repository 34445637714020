<template>
  <div v-if="filterId">
    <EnvieHeader color="purple" :filter-id="filterId" />
    <FavouriteContent :partner-id="partnerId" @loaded="scrollInitialized = true" />
  </div>
</template>

<script>
import EnvieHeader from '@/components/EnvieHeader'
import FavouriteContent from '@/components/Favourite/FavouriteContent'
import ScrollPromise from '@/mixins/ScrollPromise'

export default {
  name: 'ViewFavourite',
  components: {
    EnvieHeader,
    FavouriteContent
  },
  mixins: [ScrollPromise],
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    filterId: null
  }),
  mounted() {
    this.loading = true
    return this.$store.dispatch('partner/filterFavourite', { id: this.partnerId })
      .then(filter => (this.filterId = filter.id))
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';
/* Commercant Header slider */
#commercant-header .agile .agile__nav-button {
  background: transparent !important;
  border: none;
  position: absolute;
  bottom: 200px;
}
#commercant-header .agile__actions {
  position: absolute;
  width: 100%;
}
#commercant-header .agile__nav-button--prev {
  left: 50px;
}
#commercant-header .agile__nav-button--next {
  right: 50px;
}
#commercant-header .agile__dots {
  position: absolute;
  bottom: 0px;
  left: calc(50% - 50px);
}
#commercant-header .agile__dots .agile__dot button{
  background-color: transparent;
  width: 15px !important;
  height: 15px !important;
  margin: 10px;
  background-size: contain !important;
  border: none;
  background: url("~@/assets/slider-dot-red.svg") no-repeat;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2)) !important;
  cursor: pointer;
}
#commercant-header .agile__dot--current button{
  background: url("~@/assets/slider-dot-red-current.svg") no-repeat !important;
}
</style>
